import React from 'react'
import Img from '@app/responsive-image-sanity'
import { css } from '@emotion/core'
import SanityBlock from '@app/react-sanity-block-content'
import get from 'lodash/get'
import colors from '@data/sanity/colors.json'
import useStringBuilder from './use-string-builder-state'
import { renderStyles } from '@app/sanity-style-fields/css'
import settings from '@data/sanity/stringBuilderdeveloperSettings.json'

export default function StringSelector(props){
	const { strings } = props
	const [state, setStringState] = useStringBuilder()

	return (
		<div css={styles.container}>
			{strings.map((string, index) => {
				return (
					<a
						key={index}
						css={[styles.link, state.string == string && styles.activeLink]}
						onClick={e => {
							e.preventDefault()
							setStringState({
								string,
								step: `strands`,
							})
						}}
					>
						<div css={styles.image}>
							<Img asset={string.stringImage.asset} alt={`${string.name} string`} />
						</div>
						<h3 css={styles.stringModel}>Model: {string.id}</h3>
						<h2 css={styles.stringName}>{string.name}</h2>
						<div css={styles.stringBody}>
							<SanityBlock body={string.body} />
						</div>
					</a>
				)
			})}
			{/* <div css={styles.clear} /> */}
		</div>
	)
}

const styles = {
	image: css`
		width: 30%;
	`,
	stringModel: css`
		margin: 0;
		${renderStyles(settings.stringModelStyles)}
	`,
	stringName: css`
		margin: 0;
		${renderStyles(settings.stringNameStyles)}
	`,
	stringBody: css`
		${renderStyles(settings.stringCopyStyles)}
	`,
	container: css`
		margin: 0 -10px;
		display: flex;
	`,
	link: css`
		width: 33.33%;
		display: inline-block;
		padding: 13px 20px;
		cursor: pointer;
		color: ${get(colors, `black.hex`, `black`)};
		border: 1px solid transparent;
		opacity: .6;
		:hover, :focus{
			opacity: 1;
		}
	`,
	activeLink: css`
		border-color: ${get(colors, `wc-orange.hex`, `black`)};
	`,
}