import React from 'react'
import StringSelector from './string-selector'
import useStringBuilder from './use-string-builder-state'
import StringBuilderTemplate from './string-builder-template'

export default function StringBuilder1(props){
	const { strings, settings } = props.pageContext
	const [state] = useStringBuilder()

	return (
		<StringBuilderTemplate
			settings={settings}
			stepNumber={1}
			title='Choose a String Type'
			enableNext={!!state.string}
		>
			<StringSelector strings={strings} />
		</StringBuilderTemplate>
	)
}